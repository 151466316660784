import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import "App.css";
import "Common.css";
import Contact from "pages/Contact";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import PaymentSuccess from "./fragments/PaymentSuccess";
import ReactGA from "react-ga4";
import CopyrightPolicyFragment from "./fragments/CopyrightPolicyFragment";
import EmailPolicyFragment from "./fragments/EmailPolicyFragment";
import SignUpDoneFragment from "./fragments/SignUpDoneFragment";
import PasswordFindFragment from "./fragments/PasswordFindFragment";
import PasswordResetFragment from "./fragments/PasswordResetFragment";
import NotFoundFragment from "./fragments/NotFoundFragment";
import PcAppBar from "./components/PcAppBar";
import MobileAppBar from "./components/MobileAppBar";
import {ScrollTop} from "./components/HodeOnScroll";
import {Fab} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Colors} from "./styledcomponents/Styles";
import "bootstrap/dist/css/bootstrap.css";
import NormalUserSignUpFragment from "./fragments/NormalUserSignUpFragment";
import HomeFragment from "./fragments/HomeFragment";
import SolutionFragment from "./fragments/SolutionFragment";
import {Users} from "./modules/Users";
import CompanyAnalysisFragment from "./fragments/CompanyAnalysisFragment";
import CommonFooter from "./components/CommonFooter";
import DevPaymentFragment from "./fragments/DevPaymentFragment";
import MultiLanguageGateway from "./MultiLanguageGateway";
import PaymentResultFragment from "./fragments/PaymentResultFragment";
import PaymentWindow from "./fragments/PaymentWindow";
import {UsersAPI} from "./network/UsersAPI";
import useTablet from "./uses/UseTablet";
import NewMyFragment from "./fragments/NewMyFragment";
import * as Auths from "./modules/auths";
import AnugaFragment from "./fragments/AnugaFragment";
import {Configurations} from "./modules/Configurations";
import NewLoginFragment from "./fragments/NewLoginFragment";
import CompanyUserSignUpFirstFragment from "./fragments/CompanyUserSignUpFirstFragment";
import CompanyUserSignUpSecondFragment from "./fragments/CompanyUserSignUpSecondFragment";
import CompanySignUpDoneFragment from "./fragments/CompanySignUpDoneFragment";
import UserTypeIntroFragment from "./fragments/UserTypeIntroFragment";
import SourcingFragment from "./fragments/SourcingFragment";
import RootFragment from "./fragments/RootFragment";
import SourcingDetailFragment from "./fragments/SourcingDetailFragment";
import DevHomeFragment from "./fragments/DevHomeFragment";
import {NetworkCommon} from "./network/NetworkCommon";
import GoogleAuthTestFragment from "./fragments/GoogleAuthTestFragment";
import InquiryListFragment from "./fragments/InquiryListFragment";
import InquiryPostFragment from "./fragments/InquiryPostFragment";
import InquiryDetailFragment from "./fragments/InquiryDetailFragment";
import InquiryModifyFragment from "./fragments/InquiryModifyFragment";
import InquiryNoticeDetailFragment from "./fragments/InquiryNoticeDetailFragment";
import FaqFragment from "./fragments/FaqFragment";
import ExcelProcessingPage from "./pages/ExcelProcessingPage";

const App = () => {

    const location = useLocation();
    const isTablet = useTablet()

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const {signedIn, accessToken} = useSelector((state) => state.usersReducer, shallowEqual);
    const {paymentState} = useSelector((state) => state.auths, shallowEqual);
    const [isGaInitialized, setIsGaInitialized] = useState(false);

    useEffect(() => {
        NetworkCommon.initialize(dispatch, navigate)
    }, [])

    useEffect(() => {
        if (!accessToken) {
            // dispatch(Users.refreshToken(navigate));
            return
        }
        if (accessToken) {
            UsersAPI.getUserOrNull()
                .then((user) => {
                    if (!user) {
                        dispatch(Users.refreshToken(navigate))
                    } else {
                        dispatch(Users.updateUser(user))
                    }
                })
                .catch(e => {
                    dispatch(Users.refreshToken(navigate))
                })
        }
    }, [signedIn, accessToken])

    useEffect(() => {
        const langCode = searchParams.get('lang')
        if (langCode) {
            dispatch(Configurations.setLanguage(langCode))
        }
    }, [searchParams])

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
        setIsGaInitialized(true);
    }, []);

    useEffect(() => {
        if (!isGaInitialized) {
            return;
        }
        let pagePath = location.pathname + location.search;
        ReactGA.set({
            "page_title": pagePath,
        });
        ReactGA.send({
            hitType: "pageview",
            page: pagePath,
        });
    }, [isGaInitialized, location]);

    useEffect(() => {
        if (!accessToken) {
            dispatch(Auths.setPremiumActivated(false));
            return;
        }
        UsersAPI.getUsingService()
            .then((response) => {
                let usingServiceResult = response.data?.data;
                if (!usingServiceResult) {
                    dispatch(Auths.setPremiumActivated(false));
                    return
                }
                const itemName = usingServiceResult.itemName
                const endDate = usingServiceResult.endDate
                if (itemName && itemName.toLowerCase() === "premium" && endDate) {
                    const endTime = new Date(usingServiceResult.endDate).getTime()
                    const currentTime = new Date().getTime()
                    dispatch(Auths.setPremiumActivated(endTime > currentTime));
                }
            })
            .catch((error) => {
                dispatch(Auths.setPremiumActivated(false));
            });
    }, [accessToken, paymentState]);

    function shouldShowOnlyContent(pathname) {
        return pathname === '/dev/payment/window'
            || pathname === '/payment/result'
    }

    return (
        <RootFragment>
            {isTablet && !shouldShowOnlyContent(location.pathname) && <MobileAppBar/>}
            {!isTablet && !shouldShowOnlyContent(location.pathname) && <PcAppBar/>}
            <div className="App-content">
                <Routes>
                    <Route path="/faq" element={<FaqFragment/>}/>
                    <Route path="/sourcing-inquiry" element={<InquiryListFragment/>}/>
                    <Route path="/sourcing-inquiry/notice/:postSequence" element={<InquiryNoticeDetailFragment/>}/>
                    <Route path="/sourcing-inquiry/post/:postSequence" element={<InquiryDetailFragment/>}/>
                    <Route path="/sourcing-inquiry/post" element={<InquiryPostFragment/>}/>
                    <Route path="/sourcing-inquiry/modify/:postSequence" element={<InquiryModifyFragment/>}/>
                    <Route path="/dev/auth" element={<GoogleAuthTestFragment/>}/>
                    <Route path="/dev/payment" element={<DevPaymentFragment/>}/>
                    <Route path="/payment/window" element={<PaymentWindow/>}/>
                    <Route path="/anuga" element={<AnugaFragment/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/dashboard" element={<SolutionFragment/>}/>
                    <Route path="/sourcing" element={<SourcingFragment/>}/>
                    <Route path="/sourcing-detail" element={<SourcingDetailFragment/>}/>
                    <Route path="/login" element={<NewLoginFragment/>}/>
                    <Route path="/signup-intro" element={<UserTypeIntroFragment/>}/>
                    <Route path="/normal-user-signup" element={<NormalUserSignUpFragment/>}/>
                    <Route path="/company-user-signup-1" element={<CompanyUserSignUpFirstFragment/>}/>
                    <Route path="/company-user-signup-2" element={<CompanyUserSignUpSecondFragment/>}/>
                    <Route path="/signup-done" element={<SignUpDoneFragment/>}/>
                    <Route path="/company-signup-done" element={<CompanySignUpDoneFragment/>}/>
                    <Route path="/password-find" element={<PasswordFindFragment/>}/>
                    <Route path="/password_reset/:key" element={<PasswordResetFragment/>}/>
                    <Route path="/payment/result" element={<PaymentResultFragment/>}/>
                    <Route path="/my" element={<NewMyFragment/>}/>
                    <Route path="/terms-of-service" element={<MultiLanguageGateway/>}/>
                    <Route path="/privacy-policy" element={<MultiLanguageGateway/>}/>
                    <Route path="/copyright-policy" element={<CopyrightPolicyFragment/>}/>
                    <Route path="/email-policy" element={<EmailPolicyFragment/>}/>
                    <Route path="/company/:companyId" element={<CompanyAnalysisFragment/>}/>
                    <Route path="/payment-success" element={<PaymentSuccess/>}/>
                    <Route path="/" element={<HomeFragment/>}/>
                    <Route path="/devhome" element={<DevHomeFragment/>}/>
                    <Route path="*" element={<NotFoundFragment/>}/>
                    <Route path="/excel-processing" element={<ExcelProcessingPage/>}/>
                </Routes>
            </div>
            {!shouldShowOnlyContent(location.pathname) && <CommonFooter/>}
            {isTablet && !shouldShowOnlyContent(location.pathname) && <ScrollTop>
                <Fab
                    size="small"
                    aria-label="scroll back to top"
                    style={{backgroundColor: Colors.WHITE}}>
                    <KeyboardArrowUpIcon/>
                </Fab>
            </ScrollTop>}
        </RootFragment>
    );
};

export default App;
