import axios, {AxiosError, AxiosResponse} from "axios";
import {Logger} from "../log/Logger";
import {ResponseCodes} from "./ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Users} from "../modules/Users";
import {Dispatch} from "redux";
import {NavigateFunction} from "react-router/dist/lib/hooks";

axios.defaults.withCredentials = true;

export class NetworkCommon {

    private static BASE_URL = "https://apis.thirdworks.co.kr";
    // private static BASE_URL = "https://localhost:8000";
    private static dispatch: Dispatch<any>
    private static navigate: NavigateFunction
    static initialize = (dispatch: Dispatch<any>, navigate: NavigateFunction) => {
        NetworkCommon.dispatch = dispatch
        NetworkCommon.navigate = navigate
    }

    static createDefaultAxiosInstance = () => {
        const instance = axios.create({
            baseURL: NetworkCommon.BASE_URL,
            timeout: 50000,
        })
        instance.interceptors.request.use(config => {
                const headers = config.headers
            const token = LocalStorageManager.getAccessToken()
            if (headers && token) {
                headers.Authorization = `Bearer ${token}`
                }
                return config
            }
        )
        instance.interceptors.response.use((response: AxiosResponse<BaseResponse<any>>) => {
                const url = response.config.url
                const data = response.data
                const message = data.message
                const rawResponseCode = data.code
                const responseCode = ResponseCodes.of(rawResponseCode)
                if (responseCode.isSuccess()) {
                    //ignore
                } else if (responseCode.isAccessTokenExpired()) {
                    Logger.captureEvent({
                        level: "warning",
                        message: `web access token expired. ${message}`,
                        tags: {
                            responseCode: rawResponseCode
                        },
                        extra: {
                            responseData: data,
                            params: response.config.params,
                            url: url
                        }
                    })
                    NetworkCommon.dispatch(Users.refreshToken(NetworkCommon.navigate))

                } else if (responseCode.isNeedLogin()) {
                    Logger.captureEvent({
                        level: "warning",
                        message: `web need login`,
                        tags: {
                            responseCode: rawResponseCode
                        },
                        extra: {
                            responseData: data,
                            params: response.config.params,
                            url: url
                        }
                    })
                    NetworkCommon.navigate("/login")
                } else {
                    Logger.captureEvent({
                        level: "error",
                        message: `web invalid response code. ${responseCode} (${message})`,
                        tags: {
                            responseCode: responseCode.code
                        },
                        extra: {
                            params: response.config.params,
                            url: url
                        }
                    })
                }
                return response
            },
            (error: AxiosError<BaseResponse<any>>) => {
                const url = error.config.url
                const axiosErrorCode = error.code
                const message = error.message || axiosErrorCode
                const data = error.config.data
                const rawResponseCode = error.response?.data?.code
                const responseCode = ResponseCodes.of(error.response?.data?.code)
                let requestBody: any | null = null
                if (data) {
                    try {
                        requestBody = JSON.parse(data)
                    } catch (e) {
                    }
                }
                if (responseCode.isAccessTokenExpired()) {
                    Logger.captureEvent({
                        level: "error",
                        message: `rejected. web access token expired. ${message}`,
                        tags: {
                            axiosErrorCode: axiosErrorCode,
                            responseCode: rawResponseCode
                        },
                        extra: {
                            requestBody: requestBody,
                            responseData: error.response?.data,
                            params: error.config.params,
                            url: url
                        }
                    })
                    NetworkCommon.dispatch(Users.refreshToken(NetworkCommon.navigate))
                } else if (responseCode.isNeedLogin()) {
                    Logger.captureEvent({
                        level: "warning",
                        message: `web error. need login`,
                        tags: {
                            responseCode: rawResponseCode
                        }
                    })
                    NetworkCommon.navigate("/login")
                } else {
                    Logger.captureEvent({
                        level: "error",
                        message: `rejected. ${responseCode} (${message})`,
                        tags: {
                            axiosErrorCode: axiosErrorCode,
                            responseCode: rawResponseCode
                        },
                        extra: {
                            requestBody: requestBody,
                            responseData: error.response?.data,
                            params: error.config.params,
                            url: url
                        }
                    })
                }
                return Promise.reject(error)
            })
        return instance
    }
}

