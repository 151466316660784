import React, {useState, useEffect} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import {Colors, Fonts, Sizes} from "../styledcomponents/Styles";
import H1 from "../styledcomponents/text/H1";
import B1 from "../styledcomponents/text/B1";
import useSmall from "../uses/UseSmall";
import {Carousel} from "react-bootstrap";
import Button from "../styledcomponents/Button";
import {reactiveValueInContent, reactiveValueInFHD} from "../utils/StyleUtils";
import "./HomeFragment.css";
import H2 from "../styledcomponents/text/H2";
import B2 from "../styledcomponents/text/B2";
import ReactPlayer from "react-player";
import Text from "../styledcomponents/Text";
import H3 from "../styledcomponents/text/H3";
import B3 from "../styledcomponents/text/B3";
import useMobile from "../uses/UseMobile";
import GradientText from "../styledcomponents/GradientText";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import {useNavigate} from "react-router-dom";
import {SimpleFade} from "../components/SimpleFade";
import {useInView} from "react-intersection-observer";
import H4 from "../styledcomponents/text/H4";
import useMaxWidth from "../uses/UseMaxWidth";
import {useTranslation} from "react-i18next";
import SampleRequestDialog from "../dialog/SampleRequestDialog";
import AnugaPromotionRequestDialog from "../dialog/AnugaPromotionRequestDialog";
import AnugaPromotionDialog from "../dialog/AnugaPromotionDialog";
import useTablet from "../uses/UseTablet";
import { motion, AnimatePresence } from "framer-motion";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const HomeFragment = () => {

    const isSmall = useSmall()
    const isMobile = useMobile()
    const isTablet = useTablet()
    const isContent = useMaxWidth("1220px")
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [isOpenRequestSampleDialog, setIsOpenRequestSampleDialog] = useState(false)
    const navigate = useNavigate()
    const animationTranslationY = isMobile ? "40px" : "60px"
    const animationDuration = isMobile ? 1000 : 1200
    const [isAnugaPromotionDialog, setIsAnugaPromotionDialog] = useState(false)
    const [isAnugaPromotionRequestDialog, setIsAnugaPromotionRequestDialog] = useState(false)
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
    const [direction, setDirection] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false); 

/// 배너 자동 변경
useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 8000);
    return () => clearInterval(interval);
  }, []);


  const handlePrev = () => {
    setCurrentBannerIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % 3);
  };

    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });

    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const {t} = useTranslation()
    const brTagAboveMobile = isMobile ? " " : "\n"
    const brTagAboveSmallMobile = isSmall ? " " : "\n"

    function handleStartSolution() {
        window.open("https://solution.thirdworks.co.kr/")
    }

    return (

        <FlexBox flexDirection={"column"}
                 width={"100%"}
                 minWidth={isTablet ? undefined : "1200px"}
                 alignItems={"center"}
                 overflow={"auto"}>
            {/*------------------------BANNER section------------------------------*/}
            <FlexBox flexDirection={"column"}
            width={"100%"}
            position={"relative"}
            alignItems={"center"}
            height={isMobile ? "70vh" : "90vh"}>
    <Image 
        maxWidth={"100%"}
        maxHeight={"100%"}
        alt={"hero-image"}
        src={require("assets/images/hero_img.jpg")}
        style={{
            width: "100%",
            height: "100%", 
            objectFit: "cover", 
          }} 
    />
   <FlexBox
    flexDirection={"column"}
    width={"100%"}
    maxWidth={Sizes.CONTENT_WIDTH}
    height={"100%"}
    position={"absolute"}
    alignItems={"center"} // 모든 텍스트 수직 중앙 정렬
    justifyContent={"center"} // 모든 텍스트 수평 중앙 정렬
    textAlign={"center"}
    style={{
        transform: "translateY(-10%)", // Move text slightly up
      }}
    > 
    
                    <FlexBox height={"100%"}
                             justifyContent={"center"}
                             paddingLeft={isContent ? "10px" : "0px"}
                             paddingRight={isContent ? "10px" : "0px"}>
                       <FlexBox flexDirection={"column"}
         alignItems={"flex-start"}
         justifyContent={"center"}>
  <AnimatePresence initial={false}> 
  <div
  key={currentBannerIndex}
  style={{
    opacity: isFadingOut ? 0 : 1, // 페이드 아웃/인 효과
    transition: "opacity 0.5s ease-in-out", // 부드러운 전환
  }}
>
<H1
  reactive={true}
  color={Colors.WHITE}
  textAlign={"center"}
  style={{
    width: "100%",
    margin: "0 auto",
    wordWrap: "break-word",
    lineHeight: "1.4",
  }}
>
    {currentBannerIndex === 0 && t("home_main_banner_title")}
    {currentBannerIndex === 1 && t("home_main_banner_2_title")}
    {currentBannerIndex === 2 && t("home_main_banner_3_title")}
  </H1>
  <B1
  className="banner-text"
  reactive={true}
  marginTop={reactiveValueInFHD("30px")}
  color={Colors.WHITE}
  textAlign={"center"}
  style={{
    display: "block",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    wordWrap: "break-word",
    margin: "0 auto",
    width: "100%",
    lineHeight: "1.6",
  }}
>
  {currentBannerIndex === 0 &&
    t("home_main_banner_subtitle", { space: brTagAboveSmallMobile })}
  {currentBannerIndex === 1 &&
    t("home_main_banner_2_subtitle", { space: brTagAboveSmallMobile })}
  {currentBannerIndex === 2 &&
    t("home_main_banner_3_subtitle", { space: brTagAboveSmallMobile })}
</B1>
</div>
    </AnimatePresence>

    {currentBannerIndex === 0 && (
  <FlexBox
    width={"100%"}
    justifyContent={"center"} // 버튼을 가운데 정렬
    marginTop={reactiveValueInContent("40px")}
  >
    <Button
      backgroundColor={Colors.WHITE}
      color={Colors.MAIN}
      borderRadius={reactiveValueInContent("10px")}
      fontSize={reactiveValueInContent("20px", "12px")}
      paddingTop={reactiveValueInContent("18px", "5px")}
      paddingBottom={reactiveValueInContent("18px", "5px")}
      paddingLeft={reactiveValueInContent("58px", "10px")}
      paddingRight={reactiveValueInContent("58px", "10px")}
      height={"fit-content"}
      onClick={handleStartSolution}
    >
      {t("start_solution")}
    </Button>
  </FlexBox>
)}
                        </FlexBox>
                
                    </FlexBox>
                </FlexBox>
                
                {/* Indicator dots */}
   {/* 왼쪽 버튼 */}
<FlexBox
  position={"absolute"}
  left={"20px"}
  top={"50%"}
  transform={"translateY(-50%)"}
  zIndex={10}
  width={"40px"}
  height={"40px"}
  justifyContent={"center"}
  alignItems={"center"}
  style={{
    cursor: "pointer",
    backgroundColor: "transparent",
  }}
  onClick={() =>
    setCurrentBannerIndex((prevIndex) =>
      prevIndex === 0 ? 2 : prevIndex - 1
    )
  }
>
  <ArrowBackIosIcon
    fontSize="large"
    style={{
      color: Colors.WHITE,
      textShadow: "0px 0px 6px rgba(0, 0, 0, 0.7)", // 강조 효과
    }}
  />
</FlexBox>

{/* 오른쪽 버튼 */}
<FlexBox
  position={"absolute"}
  right={"20px"}
  top={"50%"}
  transform={"translateY(-50%)"}
  zIndex={10}
  width={"40px"}
  height={"40px"}
  justifyContent={"center"}
  alignItems={"center"}
  style={{
    cursor: "pointer",
    backgroundColor: "transparent",
  }}
  onClick={() =>
    setCurrentBannerIndex((prevIndex) =>
      prevIndex === 2 ? 0 : prevIndex + 1
    )
  }
>
  <ArrowForwardIosIcon
    fontSize="large"
    style={{
      color: Colors.WHITE,
      textShadow: "0px 0px 6px rgba(0, 0, 0, 0.7)", // 강조 효과
    }}
  />
</FlexBox>
</FlexBox>


            {/*------------------------THIRD WORKS SERVICES section------------------------------*/}
            <FlexBox
    flexDirection={"column"}
    width={"100%"}
    maxWidth={Sizes.CONTENT_WIDTH}
    alignItems={"center"}  // 수직 가운데 정렬
    justifyContent={"center"}  // 수평 가운데 정렬
    marginTop={reactiveValueInContent("120px")}
    paddingLeft={"10px"}
    paddingRight={"10px"}>
                <Image
                    alt={"home-section-key-icon-1"}
                    width={reactiveValueInContent("40px")}
                    src={require("assets/images/home-section-key-icon.svg").default}/>

                <H2 reactive={true}
                    marginTop={reactiveValueInContent("20px")}
                    color={Colors.KEY}>
                    THIRD WORKS SERVICES
                </H2>
                <B2 reactive={true}
                    whiteSpace={"pre-line"}
                    marginTop={reactiveValueInContent("20px")}
                    color={Colors.BODY_TXT}
                    textAlign={"center"}>
                    {t('home_third_works_services_description')}
                </B2>

                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <FlexBox width={reactiveValueInContent("1140px")}
                             marginTop={reactiveValueInContent("60px")}
                             minHeight={reactiveValueInContent("300px")}
                             height={"fit-content"}
                             justifyContent={"space-between"}>
                        <FlexBox borderRadius={reactiveValueInContent("30px")}
                                 background={Colors.BG1_G}
                                 width={"100%"}
                                 minHeight={"100%"}
                                 padding={reactiveValueInContent("40px", "10px")}
                                 justifyContent={"center"}
                                 alignItems={"flex-start"}>
                            <FlexBox minHeight={"fit-content"}
                                     height={"100%"}
                                     width={"100%"}
                                     justifyContent={"space-between"}>
                                <FlexBox flexDirection={"column"}
                                         justifyContent={"space-between"}>
                                    <FlexBox flexDirection={"column"}>
                                        <H4 reactive={true}
                                            paddingLeft={reactiveValueInContent("20px")}
                                            paddingRight={reactiveValueInContent("20px")}
                                            paddingTop={reactiveValueInContent("8px")}
                                            paddingBottom={reactiveValueInContent("8px")}
                                            borderRadius={reactiveValueInContent("20px")}
                                            color={Colors.WHITE}
                                            background={Colors.STANDARD}>
                                            SOURCING WORKS

                                        </H4>
                                        <Text fontWeight={700}
                                              fontSize={reactiveValueInContent("28px")}
                                              marginTop={reactiveValueInContent("20px")}>
                                            {t('home_sourcing_works_title', {space: brTagAboveMobile})}
                                        </Text>
                                    </FlexBox>
                                    <B3 reactive={true}
                                        marginTop={reactiveValueInContent("40px")}>
                                        {t('home_sourcing_works_subtitle', {space: brTagAboveMobile})}
                                    </B3>
                                </FlexBox>
                                <Image
                                    alignSelf={"flex-end"}
                                    marginLeft={"6px"}
                                    alt={"home-sourcing-works-icon"}
                                    width={reactiveValueInContent("70px")}
                                    src={require("assets/images/home-works-sourcing-icon.svg").default}/>
                            </FlexBox>

                        </FlexBox>
                        <FlexBox marginLeft={reactiveValueInContent("20px")}
                                 borderRadius={reactiveValueInContent("30px")}
                                 background={Colors.BG1_B}
                                 width={"100%"}
                                 padding={reactiveValueInContent("40px", "10px")}
                                 justifyContent={"center"}
                                 alignItems={"flex-start"}>
                            <FlexBox
                                width={"100%"}
                                justifyContent={"space-between"}
                                height={"100%"}>
                                <FlexBox flexDirection={"column"}
                                         justifyContent={"space-between"}>
                                    <FlexBox flexDirection={"column"}>
                                        <H4 reactive={true}
                                            paddingLeft={reactiveValueInContent("20px")}
                                            paddingRight={reactiveValueInContent("20px")}
                                            paddingTop={reactiveValueInContent("8px")}
                                            paddingBottom={reactiveValueInContent("8px")}
                                            borderRadius={reactiveValueInContent("20px")}
                                            color={Colors.WHITE}
                                            background={Colors.KEY}>
                                            SOLUTION WORKS

                                        </H4>
                                        <Text
                                            fontWeight={700}
                                            fontSize={reactiveValueInContent("28px")}
                                            marginTop={reactiveValueInContent("20px")}>
                                            {t('home_solution_works_title', {space: brTagAboveMobile})}
                                        </Text>
                                    </FlexBox>
                                    <B3 reactive={true}>
                                        {t('home_solution_works_subtitle', {space: brTagAboveMobile})}
                                    </B3>
                                </FlexBox>
                                <Image
                                    alignSelf={"flex-end"}
                                    marginLeft={"6px"}
                                    alt={"home-solution-works-icon"}
                                    width={reactiveValueInContent("70px")}
                                    aspectRatio={70 / 100}
                                    src={require("assets/images/home-works-solution-icon.svg").default}/>
                            </FlexBox>
                        </FlexBox>

                    </FlexBox>
                </SimpleFade>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <FlexBox flexDirection={"column"}
                             marginTop={reactiveValueInContent("20px")}
                             width={reactiveValueInContent("1140px")}
                             minHeight={reactiveValueInContent("300px")}
                             borderRadius={reactiveValueInContent("30px")}
                             padding={reactiveValueInContent("40px", "10px")}
                             background={Colors.BG1_P}
                             justifyContent={"center"}
                             alignItems={"center"}>
                        <H4 reactive={true}
                            paddingLeft={reactiveValueInContent("20px")}
                            paddingRight={reactiveValueInContent("20px")}
                            paddingTop={reactiveValueInContent("8px")}
                            paddingBottom={reactiveValueInContent("8px")}
                            borderRadius={reactiveValueInContent("20px")}
                            color={Colors.WHITE}
                            background={Colors.PREMIUM}>
                            CO-WORKS
                        </H4>
                        <H3 reactive={true}
                            marginTop={reactiveValueInContent("20px")}>
                            {t('home_co_works_title')}
                        </H3>
                        <B3 reactive={true}
                            marginTop={reactiveValueInContent("20px")}
                            textAlign={"center"}>
                            {t('home_co_works_subtitle')}
                        </B3>
                        <Image
                            position={"absolute"}
                            right={isMobile ? "10px" : "40px"}
                            bottom={isMobile ? "10px" : "40px"}
                            alt={"home-co-works-icon"}
                            width={reactiveValueInContent("120px")}
                            aspectRatio={1}
                            src={require("assets/images/home-works-co-icon.svg").default}/>
                    </FlexBox>
                </SimpleFade>
            </FlexBox>

            {/*------------------------solution section------------------------------*/}
            <FlexBox width={"100%"}
                     flexDirection={"column"}
                     alignItems={"center"}
                     marginTop={reactiveValueInContent("160px")}
                     background={Colors.BG_PAGE}>
                <FlexBox
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={reactiveValueInContent("1140px")}>
                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <Image
                            marginTop={reactiveValueInContent("100px")}
                            alt={"home-section-standard-icon"}
                            width={reactiveValueInContent("40px")}
                            src={require("assets/images/home-section-standard-icon.svg").default}/>

                        <H2 reactive={true}
                            marginTop={reactiveValueInContent("20px")}
                            color={Colors.STANDARD}>
                            SOLUTION
                        </H2>
                    </SimpleFade>

                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <FlexBox marginTop={reactiveValueInContent("100px")}
                                 justifyContent={"center"}
                                 width={"100%"}
                                 alignItems={"center"}>
                            <Image
                                alt={"home-solution-section-card-1"}
                                width={reactiveValueInContent("600px")}
                                aspectRatio={760 / 520}
                                src={require(`assets/images/${language}/home-solution-section-card-1.png`)}/>
                            <FlexBox flexDirection={"column"}
                                     marginLeft={reactiveValueInContent("80px", "10px")}>
                                <Text
                                    borderRadius={reactiveValueInContent("30px")}
                                    fontSize={reactiveValueInContent("18px", "12px")}
                                    paddingLeft={reactiveValueInContent("16px")}
                                    paddingRight={reactiveValueInContent("16px")}
                                    paddingTop={reactiveValueInContent("9px")}
                                    paddingBottom={reactiveValueInContent("9px")}
                                    border={`1px solid ${Colors.GREEN_40}`}
                                    color={Colors.GREEN_40}
                                    fontWeight={Fonts.WEIGHT_700}>
                                    Standard
                                </Text>

                                <H3 reactive={true}
                                    marginTop={reactiveValueInContent("24px")}>
                                    {t('home_import_trends')}
                                </H3>
                                <B3 reactive={true}
                                    color={Colors.BODY_TXT}
                                    marginTop={reactiveValueInContent("40px")}
                                    width={"100%"}
                                    minWidth={reactiveValueInContent("360px")}>
                                    {t('home_import_trends_description', {space: brTagAboveMobile})}
                                </B3>
                            </FlexBox>
                        </FlexBox>
                    </SimpleFade>

                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <FlexBox marginTop={reactiveValueInContent("200px")}
                                 justifyContent={"center"}
                                 alignItems={"center"}>
                            <FlexBox flexDirection={"column"}>
                                <Text
                                    borderRadius={reactiveValueInContent("30px")}
                                    fontSize={reactiveValueInContent("18px", "12px")}
                                    paddingLeft={reactiveValueInContent("16px")}
                                    paddingRight={reactiveValueInContent("16px")}
                                    paddingTop={reactiveValueInContent("9px")}
                                    paddingBottom={reactiveValueInContent("9px")}
                                    border={`1px solid ${Colors.GREEN_40}`}
                                    color={Colors.GREEN_40}
                                    fontWeight={Fonts.WEIGHT_700}>
                                    Standard
                                </Text>

                                <H3 reactive={true}
                                    marginTop={reactiveValueInContent("24px")}>
                                    {t('home_market_trends')}
                                </H3>
                                <B3 reactive={true}
                                    color={Colors.BODY_TXT}
                                    marginTop={reactiveValueInContent("40px")}
                                    width={"100%"}
                                    minWidth={reactiveValueInContent("320px")}>
                                    {t('home_market_trends_description', {space: brTagAboveMobile})}
                                </B3>
                            </FlexBox>
                            <Image
                                alt={"home-solution-section-card-2"}
                                width={reactiveValueInContent("600px")}
                                aspectRatio={760 / 520}
                                marginLeft={reactiveValueInContent("80px", "10px")}
                                src={require("assets/images/home-solution-section-card-2.svg").default}/>

                        </FlexBox>
                    </SimpleFade>

                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <FlexBox marginTop={reactiveValueInContent("200px")}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                                 marginBottom={reactiveValueInContent("160px")}>
                            <Image
                                alt={"home-solution-section-card-3"}
                                width={reactiveValueInContent("600px")}
                                aspectRatio={760 / 520}
                                src={require(`assets/images/${language}/home-solution-section-card-3.png`)}/>
                            <FlexBox flexDirection={"column"}
                                     marginLeft={reactiveValueInContent("80px", "10px")}>
                                <Text
                                    borderRadius={reactiveValueInContent("30px")}
                                    fontSize={reactiveValueInContent("18px", "12px")}
                                    paddingLeft={reactiveValueInContent("16px")}
                                    paddingRight={reactiveValueInContent("16px")}
                                    paddingTop={reactiveValueInContent("9px")}
                                    paddingBottom={reactiveValueInContent("9px")}
                                    border={`1px solid ${Colors.PREMIUM}`}
                                    color={Colors.PREMIUM}
                                    fontWeight={Fonts.WEIGHT_700}>
                                    Premium
                                </Text>

                                <H3 reactive={true}
                                    marginTop={reactiveValueInContent("24px")}>
                                    {t('home_supply_chain_analysis')}
                                </H3>
                                <B3 reactive={true}
                                    color={Colors.BODY_TXT}
                                    marginTop={reactiveValueInContent("40px")}
                                    width={"100%"}
                                    minWidth={reactiveValueInContent("320px")}>
                                    {t('home_supply_chain_analysis_description', {space: brTagAboveMobile})}
                                </B3>
                            </FlexBox>


                        </FlexBox>
                    </SimpleFade>
                </FlexBox>
            </FlexBox>

            {/*------------------------report section------------------------------*/}
            <FlexBox
    width={reactiveValueInContent("1140px")}
    flexDirection={"column"}
    alignItems={"center"}  // 수직 가운데 정렬
    justifyContent={"center"}  // 수평 가운데 정렬
    marginTop={reactiveValueInContent("60px")}>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}
                    fraction={0.3}>
                    <Text
                        marginTop={reactiveValueInContent("100px")}
                        borderRadius={reactiveValueInContent("30px")}
                        fontSize={reactiveValueInContent("18px", "12px")}
                        paddingLeft={reactiveValueInContent("16px")}
                        paddingRight={reactiveValueInContent("16px")}
                        paddingTop={reactiveValueInContent("9px")}
                        paddingBottom={reactiveValueInContent("9px")}
                        border={`1px solid ${Colors.PREMIUM}`}
                        color={Colors.PREMIUM}
                        fontWeight={Fonts.WEIGHT_700}>
                        Premium
                    </Text>
                    <H2 reactive={true}
                        color={Colors.PREMIUM}
                        marginTop={reactiveValueInContent("30px")}>
                        THIRD WORKS MARKET REPORT
                    </H2>
                    <B2 reactive={true}
                        color={Colors.BODY_TXT}
                        marginTop={reactiveValueInContent("20px")}
                        textAlign={"center"}>
                        {t('home_market_report_description', {space: isSmall ? " " : "\n"})}
                    </B2>
                </SimpleFade>
                <FlexBox flexDirection={"column"}
                         alignItems={"center"}
                         borderRadius={"30px"}
                         width={reactiveValueInContent("1140px")}
                         background={Colors.KEY}
                         marginTop={reactiveValueInContent("60px")}>
                    <SimpleFade
                        fraction={0.2}
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <Image
                            alt={"home-report"}
                            width={"100%"}
                            src={require(`assets/images/${language}/home-report.png`)}/>
                    </SimpleFade>
                </FlexBox>

                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <GradientText
                        fontSize={reactiveValueInContent("44px", "14px")}
                        fontWeight={Fonts.WEIGHT_700}
                        marginTop={reactiveValueInContent("170px")}
                        background={Colors.GRADIENT_BUTTON2_BACKGROUND}>
                        {t('home_trend_report_introduce')}
                    </GradientText>

                    <Button width={reactiveValueInContent("360px", "120px")}
                            height={reactiveValueInContent("80px", "36px")}
                            marginTop={reactiveValueInContent("40px", "16px")}
                            marginBottom={reactiveValueInContent("200px", "80px")}
                            borderRadius={reactiveValueInContent("16px")}
                            fontSize={reactiveValueInContent("24px", "12px")}
                            onClick={() => {
                                setIsOpenRequestSampleDialog(true)
                            }}>
                        {t('request_sample_report')}
                    </Button>
                </SimpleFade>
            </FlexBox>

            {/*------------------------SOURCING section------------------------------*/}
            <FlexBox
                flexDirection={"column"}
                alignItems={"center"}
                width={"100%"}>
                <FlexBox
                    flexDirection={"column"}
                    width={"100%"}
                    alignItems={"center"}
                    background={"#F5F9FF"}>
                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}>
                        <Image
                            marginTop={reactiveValueInContent("160px")}
                            alt={"home-section-sourcing-icon"}
                            width={reactiveValueInContent("40px")}
                            src={require("assets/images/home-section-key-icon.svg").default}/>

                        <Text marginTop={reactiveValueInContent("20px")}
                              color={Colors.KEY}
                              fontSize={reactiveValueInContent("80px", "12px")}
                              fontWeight={Fonts.WEIGHT_700}>
                            SOURCING
                        </Text>

                        <Text
                            textAlign={"center"}
                            fontSize={reactiveValueInContent("36px", "12px")}
                            marginTop={reactiveValueInContent("40px")}
                            marginBottom={reactiveValueInContent("508px")}
                            color={Colors.BODY_TXT}>
                            {t('home_sourcing_section_description')}
                        </Text>
                    </SimpleFade>

                </FlexBox>
                <FlexBox height={reactiveValueInContent("116px")}/>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <Image
                        alt={"home-sourcing-section-image"}
                        width={reactiveValueInContent("960px")}
                        position={"absolute"}
                        bottom={"0px"}
                        left={"50%"}
                        transform={"translate(-50%, 0%)"}
                        src={require("assets/images/home-sourcing-section-image.svg").default}/>
                </SimpleFade>
            </FlexBox>


            {/*------------------------A to Z solution section------------------------------*/
            }
            <FlexBox width={reactiveValueInContent("1140px")}
                     flexDirection={"column"}
                     alignItems={"center"}
                     marginTop={reactiveValueInContent("180px")}>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <Image
                        alt={"home-section-solution-az-icon"}
                        width={reactiveValueInContent("40px")}
                        src={require("assets/images/home-section-standard-icon.svg").default}/>

                    <Text marginTop={reactiveValueInContent("20px")}
                          color={Colors.STANDARD}
                          textAlign={"center"}
                          fontSize={reactiveValueInContent("54px", "12px")}
                          fontWeight={Fonts.WEIGHT_700}>
                        {t('home_a_to_z_title')}
                    </Text>
                </SimpleFade>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <FlexBox flexDirection={"row"}
                             marginTop={reactiveValueInContent("60px")}
                             width={reactiveValueInContent("1100px")}
                             height={reactiveValueInContent("320px")}>
                        <FlexBox flexDirection={"column"}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                                 background={Colors.BG1_B}
                                 borderRadius={reactiveValueInContent("30px")}
                                 width={"100%"}>
                            <Text
                                fontSize={reactiveValueInContent("40px", "12px")}
                                color={Colors.KEY}
                                fontWeight={Fonts.WEIGHT_700}>
                                <span style={{fontSize: reactiveValueInContent("80px")}}>30</span>%
                            </Text>
                            <Text fontSize={reactiveValueInContent("30px", "12px")}
                                  color={Colors.KEY}
                                  textAlign={"center"}
                                  marginTop={reactiveValueInContent("20px")}>
                                {t('reduce_market_research_costs')}
                            </Text>

                        </FlexBox>
                        <FlexBox flexDirection={"column"}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                                 marginLeft={reactiveValueInContent("20px")}
                                 background={Colors.BG1_G}
                                 borderRadius={reactiveValueInContent("30px")}
                                 width={"100%"}>
                            <Text
                                fontSize={reactiveValueInContent("40px", "12px")}
                                color={Colors.STANDARD}
                                fontWeight={Fonts.WEIGHT_700}>
                                <span style={{fontSize: reactiveValueInContent("80px")}}>70</span>%
                            </Text>
                            <Text fontSize={reactiveValueInContent("30px", "12px")}
                                  color={Colors.STANDARD}
                                  textAlign={"center"}
                                  marginTop={reactiveValueInContent("20px")}>
                                {t('improve_trade_efficiency')}
                            </Text>

                        </FlexBox>
                        <FlexBox flexDirection={"column"}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                                 marginLeft={reactiveValueInContent("20px")}
                                 background={Colors.BG1_P}
                                 borderRadius={reactiveValueInContent("30px")}
                                 width={"100%"}>
                            <Text
                                fontSize={reactiveValueInContent("40px", "12px")}
                                color={Colors.PREMIUM}
                                fontWeight={Fonts.WEIGHT_700}>
                                <span
                                    style={{fontSize: reactiveValueInContent("80px")}}>{t('retain_imported_food_data_count')}</span>{t('retain_imported_food_data_unit')}
                            </Text>
                            <Text fontSize={reactiveValueInContent("30px", "12px")}
                                  color={Colors.PREMIUM}
                                  textAlign={"center"}
                                  marginTop={reactiveValueInContent("20px")}>
                                {t('retain_imported_food_data')}
                            </Text>

                        </FlexBox>

                    </FlexBox>
                </SimpleFade>
            </FlexBox>


            {/*------------------------player section------------------------------*/
            }
            <FlexBox flexDirection={"column"}
                     width={"100%"}
                     background={Colors.BG_PAGE}
                     alignItems={"center"}
                     marginTop={reactiveValueInContent("240px")}
                     paddingBottom={reactiveValueInContent("360px")}>
                <SimpleFade
                    translationY={animationTranslationY}
                    duration={animationDuration}>
                    <Image
                        marginTop={reactiveValueInContent("100px")}
                        alt={"home-section-key-icon-2"}
                        width={reactiveValueInContent("40px")}
                        src={require("assets/images/home-section-key-icon.svg").default}/>
                    <H2 reactive={true}
                        marginTop={reactiveValueInContent("20px")}
                        marginBottom={reactiveValueInContent("60px")}
                        color={Colors.KEY}
                        textAlign={"center"}>
                        {`THIRD WORKS\n${t('user_guide')}`}
                    </H2>
                </SimpleFade>
                <FlexBox flexDirection={"column"}
                         width={"100%"}
                         marginTop={"20px"}
                         aspectRatio={16 / 9}
                         maxWidth={reactiveValueInContent("1200px")}>
                    <SimpleFade
                        translationY={animationTranslationY}
                        duration={animationDuration}
                        style={{
                            width: "100%",
                            height: "100%"
                        }}>
                        <ReactPlayer
                            width={"100%"}
                            url={require(`assets/videos/${language}/introduce.mp4`)}
                            light={require(`assets/images/${language}/home-player-thumbnail.png`)}
                            playing={true}
                            height={"100%"}
                            controls={true}
                        />
                    </SimpleFade>
                </FlexBox>
            </FlexBox>
            <SampleRequestDialog isOpen={isOpenRequestSampleDialog}
                                 onClosed={() => {
                                     setIsOpenRequestSampleDialog(false)
                                 }}/>
            <AnugaPromotionDialog dialogProps={{
                isOpen: isAnugaPromotionDialog,
                onClose: (positive) => {
                    setIsAnugaPromotionDialog(false)
                    setIsAnugaPromotionRequestDialog(positive)
                }
            }}/>
            <AnugaPromotionRequestDialog dialogProps={{
                isOpen: isAnugaPromotionRequestDialog,
                onClose: (positive) => {
                    setIsAnugaPromotionRequestDialog(false)
                }
            }}/>
        </FlexBox>
    )
}
export default HomeFragment;