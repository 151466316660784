import React, { useState } from "react";
import axios from "axios";
import { Upload, FileUp, CheckCircle2, AlertCircle } from "lucide-react";
import {
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Typography,
    Button,
    Alert,
    CircularProgress,
    Box,
} from "@mui/material";
import { LocalStorageManager } from "../LocalStorageManager";

const ExcelProcessingPage: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);  
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<{ type: string; message: string }>({ type: "", message: "" });
    // const API_URL = 'https://localhost:8000/rawdata/originalThirdworksData';
    const API_URL = 'https://apis.thirdworks.co.kr/rawdata/originalThirdworksData';

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
        if (e.target.files) {
            setFile(e.target.files[0]);
            setStatus({ type: "", message: "" });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {  
        e.preventDefault();
        if (!file) return;
    
        setLoading(true);
        setStatus({ type: "", message: "" });
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const token = LocalStorageManager.getAccessToken(); // access-token 가져오기
            const response = await axios.post(API_URL, formData, {
                headers: { Authorization: `Bearer ${token}` },
                withCredentials: true,  
                responseType: 'blob',
                timeout: 300000,
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            console.log(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `processed_${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
    
            setStatus({
                type: "success",
                message: "파일이 성공적으로 처리되었습니다."
            });
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const responseData = error.response.data as { code: string; message: string };
                if (responseData.code === "0099") {
                    setStatus({
                        type: "error",
                        message: "로그인이 필요합니다. 로그인 페이지로 이동합니다."
                    });
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 2000);
                } else {
                    setStatus({
                        type: "error",
                        message: `오류가 발생했습니다: ${responseData.message || '알 수 없는 오류'}`
                    });
                }
            } else {
                setStatus({
                    type: "error",
                    message: "파일 처리 중 오류가 발생했습니다."
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f5f5f5">
            <Card sx={{ maxWidth: 600, width: "100%", p: 2 }}>
                <CardHeader
                    avatar={<FileUp size={24} />}
                    title="엑셀 데이터 처리"
                    subheader="처리할 엑셀 파일(.xls, .xlsx)을 업로드해주세요."
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Box mb={3}>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept=".xls,.xlsx"
                                style={{ display: "none" }}
                                id="upload-file"
                            />
                            <label htmlFor="upload-file">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    fullWidth
                                    startIcon={<Upload />}
                                    sx={{ mt: 1 }}
                                >
                                    {file ? file.name : "파일을 선택하거나 드래그하세요"}
                                </Button>
                            </label>
                        </Box>
                        <CardActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!file || loading}
                                startIcon={loading ? <CircularProgress size={20} /> : <Upload />}
                            >
                                {loading ? "처리중..." : "업로드 및 처리"}
                            </Button>
                        </CardActions>
                    </form>

                    {status.type && (
    <Alert
        severity={status.type === "success" ? "success" : "error"}
        sx={{ mt: 2 }}
        iconMapping={{
            success: <CheckCircle2 />,
            error: <AlertCircle />,
        }}
    >
        <Typography variant="body2">{status.message}</Typography>
    </Alert>
)}
                </CardContent>
            </Card>
        </Box>
    );
};

export default ExcelProcessingPage;