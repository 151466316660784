import React, {MouseEvent, useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../modules";
import ReactGA from "react-ga4";
import {ContentContainer} from "../styledcomponents/Containers";
import FlexBox from "styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import styled from "styled-components";
import CommonComponent from "../styledcomponents/CommonComponent";
import {Colors, Fonts} from "../styledcomponents/Styles";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {Logger} from "../log/Logger";
import {LocalStorageManager} from "../LocalStorageManager";
import {Users} from "../modules/Users";
import {useTranslation} from "react-i18next";
import NewButton from "../styledcomponents/NewButton";
import {FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Shadows, ThemeProvider} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import NewH6 from "../styledcomponents/text/NewH6";
import {Configurations} from "../modules/Configurations";
import {createTheme} from "@mui/material/styles";

/**
 * @author jaeho.lee104 on 2023. 04. 08..
 */

const theme = createTheme({
    shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
    components: {
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: Colors.WHITE,
                    padding: "4px",
                    marginTop: "4px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
                    border: `1px solid ${Colors.GRAY_3}`
                }
            }
        },
        MuiList: {
            defaultProps: {
                sx: {
                    padding: "0px",
                }
            }
        },
    },
    palette: {
        action: {
            selected: Colors.MAIN,
        }
    }
});

interface NavigationItemProps {
    selected: boolean
}

const NavigationItem = styled(CommonComponent)<NavigationItemProps>`
    font-weight: ${({selected}) => selected ? Fonts.WEIGHT_700 : Fonts.WEIGHT_500};
     color: ${({ selected }) => (selected ? '#007BFF' : '#ffffff')}; /* 선택된 메뉴 색상 */
    font-size: 16px;
    cursor: pointer;
    margin-right: 36px;

    &:hover {
        color: ${Colors.MAIN_LIGHT}
    }
`;

const PcAppBar = () => {
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const componentName = "PcAppBar"
    const {t} = useTranslation()


    useEffect(() => {
        let pathname = location.pathname;
        if (pathname === "/") {
            setSelectedMenuIndex(0);
        }   else if (pathname === "/aboutUs") {
            setSelectedMenuIndex(1); 
        }
        else if (pathname === "/dashboard") {
            setSelectedMenuIndex(2);
        } else if (pathname === "/sourcing-inquiry") {
            setSelectedMenuIndex(3);
        } else if (pathname === "/faq") {
            setSelectedMenuIndex(4);
        } else {
            setSelectedMenuIndex(999);
        }
    }, [location]);

    function handleLoginOrLogout(event: MouseEvent<any>) {
        if (signedIn) {
            ReactGA.event({
                category: "pc_app_bar",
                action: `logout`
            });
            Logger.info(componentName, `logout. userId: ${LocalStorageManager.getUserId()}`)
            dispatch(Users.logout());
            navigate("/");

        } else {
            ReactGA.event({
                category: "pc_app_bar",
                action: `login`
            });
            if (KeyboardUtils.isNewWindowTriggerKey(event)) {
                window.open("/login")
            } else {
                navigate("/login")
            }
        }
    }

    const LanguageMenu: React.FC = () => {
        return (
            <FlexBox
                alignItems={"center"}
                height={"100%"}
                position={"absolute"}
                right={"20px"}
                width={"160px"}
            >
                <ThemeProvider theme={theme}>
                    <FormControl sx={{ width: "100%" }}>
                        <Select
                            IconComponent={(props) => (
                                <ExpandMoreOutlined
                                    {...props}
                                    sx={{
                                        color: "#ffffff", // 화살표 아이콘 색상을 흰색으로 설정
                                    }}
                                />
                            )}
                            input={
                                <OutlinedInput
                                    style={{
                                        height: "40px",
                                    }}
                                />
                            }
                            value={language}
                            renderValue={(value) => {
                                return (
                                    <FlexBox alignItems={"center"}>
                                        <LanguageIcon
                                            sx={{
                                                marginRight: "4px",
                                                width: "24px",
                                                height: "24px",
                                                color: "#ffffff", // 언어 아이콘 색상
                                            }}
                                        />
                                        <NewH6
                                            textOverflow={"ellipsis"}
                                            whiteSpace={"nowrap"}
                                            overflow={"hidden"}
                                            color={"#ffffff"} // 기본 글자 색상
                                            marginLeft={"4px"}
                                        >
                                            {t("lang_" + value)}
                                        </NewH6>
                                    </FlexBox>
                                );
                            }}
                            onChange={(e: SelectChangeEvent) => {
                                const selectedLanguage = e.target.value;
                                if (language !== selectedLanguage) {
                                    dispatch(Configurations.setLanguage(selectedLanguage));
                                }
                            }}
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    color: "#ffffff", // 화살표 아이콘 색상
                                },
                                color: "#ffffff", // 기본 텍스트 색상
                                fontSize: "14px",
                                borderRadius: "5px",
                                "&& fieldset": {
                                    border: `1px solid ${Colors.GRAY_3}`,
                                },
                                "&:hover": {
                                    "&& fieldset": {
                                        border: `1px solid ${Colors.CTA_HOVER}`,
                                    },
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: `2px solid ${Colors.MAIN}`,
                                },
                            }}
                        >
                            <MenuItem
                                sx={{
                                    margin: "4px",
                                    fontSize: "14px",
                                    borderRadius: "8px",
                                }}
                                value={"kr"}
                            >
                                {t("lang_kr")}
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    margin: "4px",
                                    fontSize: "14px",
                                    borderRadius: "8px",
                                }}
                                value={"en"}
                            >
                                {t("lang_en")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </ThemeProvider>
            </FlexBox>
        );
    };


    return (
        <>
            <FlexBox
                width={"100%"}
                minWidth={"1200px"}
                style={{
                    position: "fixed", // 화면 상단에 고정
                    top: 0,
                    left: 0,
                    zIndex: 1000, // 헤더가 항상 위에 표시되도록 설정
                    background: "rgba(0, 0, 0, 0.5)", // 배경 투명도 설정
                }}
                >
                <ContentContainer
                    width={"100%"}
                    maxWidth={"1240px"}
                    paddingLeft={"20px"}
                    paddingRight={"20px"}>
                    <FlexBox alignItems={"center"}
                             minHeight={"64px"}>
                        <Image
                            width={"205px"}
                            height={"20px"}
                            cursor={"pointer"}
                            src={require("assets/images/app-bar-logo2.svg").default}
                            alignSelf={"center"}
                            onClick={() => {
                                ReactGA.event({
                                    category: "app_bar",
                                    action: `logo_click`,
                                });
                                navigate("/")
                            }}
                            alt="app-bar-logo"/>
                        <FlexBox
                            alignItems={"center"}
                            width={"100%"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                marginLeft={"40px"}>
                                <NavigationItem
                                    selected={selectedMenuIndex === 0}
                                    onClick={(e) => {
                                        ReactGA.event({
                                            category: "app_bar",
                                            action: `home_click`,
                                        });
                                        if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                            window.open("/")
                                        } else {
                                            navigate("/")
                                        }
                                    }}>{t('home')}</NavigationItem>
                                    <NavigationItem
    selected={selectedMenuIndex === 1}
    onClick={(e) => {
        ReactGA.event({
            category: "app_bar",
            action: `about_click`,
        });
        if (KeyboardUtils.isNewWindowTriggerKey(e)) {
            window.open("https://about.thirdworks.co.kr/", "_blank"); 
        } else {
            window.location.href = ("https://about.thirdworks.co.kr/");
        }
    }}
>
{t('about')}
</NavigationItem>
                                <NavigationItem
                                    selected={selectedMenuIndex === 2}
                                    onClick={(e) => {
                                        ReactGA.event({
                                            category: "app_bar",
                                            action: `dashboard_click`,
                                        });
                                        if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                            window.open("/dashboard")
                                        } else {
                                            navigate("/dashboard")
                                        }
                                    }}>{t('dashboard')}</NavigationItem>
                                <NavigationItem
                                    selected={selectedMenuIndex === 3}
                                    onClick={(e) => {
                                        ReactGA.event({
                                            category: "app_bar",
                                            action: `sourcing_inquiry_click`,
                                        });
                                        if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                            window.open("/sourcing-inquiry")
                                        } else {
                                            navigate("/sourcing-inquiry")
                                        }
                                    }}>{t('sourcing_inquiry')}</NavigationItem>
                                <NavigationItem
                                    selected={selectedMenuIndex === 4}
                                    onClick={(e) => {
                                        ReactGA.event({
                                            category: "app_bar",
                                            action: `faq_click`,
                                        });
                                        if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                            window.open("/faq")
                                        } else {
                                            navigate("/faq")
                                        }
                                    }}>{'FAQ'}</NavigationItem>
                                  
</FlexBox>
                            <FlexBox>
                                <Image
                                    width={"32px"}
                                    height={"32px"}
                                    cursor={"pointer"}
                                    marginRight={"20px"}
                                    src={require("assets/images/ic-my.svg").default}
                                    alignSelf={"center"}
                                    onClick={(e) => {
                                        if (signedIn) {
                                            if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                                window.open("/my?index=0")
                                            } else {
                                                navigate("/my?index=0")
                                            }
                                        } else {
                                            alert(t('required_login'))
                                            navigate("/login")
                                        }
                                    }}
                                    alt="app-bar-my"/>
                                <NewButton
                                    minWidth={"fit-content"}
                                    marginRight={"180px"}
                                    padding={"10px 16px"}
                                    colorType={0}
                                    borderRadius={"8px"}
                                    minHeight={"44px"}
                                    alignSelf={"center"}
                                    height={"44px"}
                                    onClick={(e) => handleLoginOrLogout(e)}>
                                    {signedIn ? t("logout") : t("login")}
                                </NewButton>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </ContentContainer>
                <LanguageMenu/>
            </FlexBox>
        </>
    )
}
export default PcAppBar;