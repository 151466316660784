// noinspection PointlessBooleanExpressionJS

import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import Text from "../styledcomponents/Text";
import {Colors, Sizes} from "../styledcomponents/Styles";
import styled from "styled-components";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {LanguageUtils} from "../utils/LanguageUtils";
import useTablet from "../uses/UseTablet";

const PolicyText = styled(Text)`
    max-width: ${props => props.maxWidth};
    color: ${Colors.SUB_TXT};
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
    font-weight: normal;
    cursor: pointer;
`

const ContentText = styled(Text)`
    color: ${Colors.SUB_TXT2};
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
    font-weight: normal;
`

const LabelText = styled(Text)`
    color: ${Colors.SUB_TXT2};
    font-weight: 700;
    font-size: ${props => props.fontSize};
    line-height: ${props => props.lineHeight};
`

const CommonFooter = () => {

    const isTablet = useTablet()


    const navigate = useNavigate()
    const {t} = useTranslation();
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const dispatch = useDispatch();

    {/*------------------ PC ------------------*/
    }
    return <FlexBox
        borderTop={`1px solid ${Colors.GRAY_3}`}
        backgroundColor={Colors.GRAY_0}
        width={"100%"}
        minWidth={isTablet ? undefined : "1200px"}>
        {!isTablet && <FlexBox width={"100%"}
                               alignItems={"center"}
                               justifyContent={"center"}
                               padding={"40px"}>
            <FlexBox width={"100%"}
                     maxWidth={Sizes.CONTENT_WIDTH}
                     height={"100%"}
                     alignItems={"center"}>
                <FlexBox
                    width={"100%"}
                    height={"100%"}
                    flexDirection={"column"}
                    justifyContent={"center"}>
                    <Image
                        alt={"footer-logo"}
                        width={"194px"}
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            navigate("/")
                        }}
                        src={require("assets/images/footer-logo.svg").default}/>
                    <Text
                        marginTop={"20px"}
                        fontSize={"12px"}
                        lineHeight={"12px"}
                        color={Colors.GRAY_400}>COPYRIGHT 2023 ⓒ THIRDWORKS ALL RIGHTS RESERVED </Text>
                </FlexBox>
                <FlexBox
                    height={"100%"}
                    width={"100%"}
                    minWidth={"700px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={"center"}>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            onClick={() => {
                                window.open("/terms-of-service")
                            }}>
                            {t('terms_of_service')}
                        </PolicyText>
                        <PolicyText
                            marginLeft={"20px"}
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            onClick={() => {
                                window.open("/privacy-policy")
                            }}>
                            {t('privacy_policy')}
                        </PolicyText>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            visible={LanguageUtils.isKorean(language)}
                            marginLeft={"20px"}
                            onClick={() => {
                                window.open("/copyright-policy")
                            }}>
                            {t('copyright_policy')}
                        </PolicyText>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            visible={LanguageUtils.isKorean(language)}
                            marginLeft={"20px"}
                            onClick={() => {
                                window.open("/email-policy")
                            }}>
                            {t('deny_unauthorized_collection_of_email_addresses')}
                        </PolicyText>
                    </FlexBox>
                    <HorizontalDivider
                        paddingTop={"1px"}
                        marginTop={"20px"}
                        backgroundColor={"#E3E3E3"}/>
                    <FlexBox
                        marginTop={"30px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('footer_company_name')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('third_works_company')}
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('representative')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('kim_minji')}
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('representative_email')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            business@thirdworks.co.kr
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('business_registration_number')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            784-87-02728
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('e_commerce_registration_number_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('e_commerce_registration_number')}
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('cpo')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('kim_minji')}
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('business_registration_number')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            784-87-02728
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('e_commerce_registration_number_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('e_commerce_registration_number')}
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('cpo')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('kim_minji')}
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        marginTop={"10px"}
                        alignItems={"center"}>
                    </FlexBox>
                    <FlexBox
                        visible={LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        flexDirection={isTablet ? "column" : "row"} /* 태블릿에서는 column, 데스크톱에서는 row */
                        alignItems={"flex-start"}
                        >
                       <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}
                        style={{
                        whiteSpace: "nowrap", // LabelText는 줄 바꿈을 방지
                        }}
                        >
                            {t('address_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}
                            style={{
                                maxWidth: isTablet ? "100%" : "400px", // 최대 너비 설정
                                overflowWrap: "break-word", // 텍스트가 FlexBox 너비를 초과하면 줄 바꿈
                                wordBreak: "break-word", // 단어가 너무 길 경우 단어 단위로 줄 바꿈
                            }}>
                            {t('address_detail')}
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('phone_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}
                            style={{
                                whiteSpace: "nowrap", // 전화번호는 줄 바꿈 방지
                            }}>
                            010-2476-9700
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"flex-start"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('address_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            {t('address_detail')}
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('phone_label')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            010-2476-9700
                        </ContentText>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>}
        {/*------------------ Mobile ------------------*/}
        {isTablet && <FlexBox width={"100%"}
                              flexDirection={"column"}>
            <FlexBox minWidth={"360px"}
                     width={"360px"}
                     margin={"0 auto"}
                     flexDirection={"column"}
                     paddingLeft={"10px"}
                     paddingRight={"10px"}
                     paddingTop={"40px"}
                     paddingBottom={"40px"}>
                <Image
                    alt={"footer-logo"}
                    width={"194px"}
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        navigate("/")
                    }}
                    src={require("assets/images/footer-logo.svg").default}/>
                <Text
                    marginTop={"20px"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    color={Colors.GRAY_400}>COPYRIGHT 2023 ⓒ THIRDWORKS ALL RIGHTS RESERVED </Text>

                <FlexBox
                    marginTop={"20px"}
                    alignItems={"center"}>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        width={"100px"}
                        onClick={() => {
                            window.open("/terms-of-service")
                        }}>
                        {t('terms_of_service')}
                    </PolicyText>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        marginLeft={"20px"}
                        onClick={() => {
                            window.open("/privacy-policy")
                        }}>
                        {t('privacy_policy')}
                    </PolicyText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        width={"100px"}
                        onClick={() => {
                            window.open("/copyright-policy")
                        }}>
                        {t('copyright_policy')}
                    </PolicyText>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        marginLeft={"20px"}
                        onClick={() => {
                            window.open("/email-policy")
                        }}>
                        {t('deny_unauthorized_collection_of_email_addresses')}
                    </PolicyText>
                </FlexBox>
                <HorizontalDivider
                    marginTop={"20px"}
                    backgroundColor={"#E3E3E3"}/>
                <FlexBox
                    marginTop={"20px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('footer_company_name')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('third_works_company')}
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('representative')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('kim_minji')}
                    </ContentText>
                    <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                     height={"12px"}
                                     marginLeft={"10px"}
                                     marginRight={"10px"}/>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('representative_email')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        business@thirdworks.co.kr
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('representative')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('kim_minji')}
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('representative_email')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        business@thirdworks.co.kr
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('business_registration_number')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        784-87-02728
                    </ContentText>
                    <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                     height={"12px"}
                                     marginLeft={"10px"}
                                     marginRight={"10px"}/>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('cpo')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('kim_minji')}
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('business_registration_number')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        784-87-02728
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('cpo')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('kim_minji')}
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('e_commerce_registration_number_label')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('e_commerce_registration_number')}
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('phone_label')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        010-2476-9700
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"flex-start"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('address_label')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        {t('address_detail')}
                    </ContentText>
                </FlexBox>
            </FlexBox>
        </FlexBox>}
    </FlexBox>
}
export default CommonFooter;